import { Component, Mixins } from 'vue-property-decorator';

import headerBg from '~/assets/images/header-bg-default.jpg';
import acckaMember from '~/assets/images/accka-member.jpg';
import concession from '~/assets/images/koncesni-listina.jpg';
import insuranceIns from '~/assets/pojistka-ins.pdf';
import insuranceOdp from '~/assets/pojistka-odp.pdf';
import qualityCert from '~/assets/q22110-elixir-tours.pdf';
import Header from '~/components/templates/Header';
import HeadManagement from '~/mixins/HeadManagement';
import { getLocalizedRoute, routes } from '~/app/core/router/routes';

@Component
export default class Contact extends Mixins(HeadManagement) {
  public title() {
    return this.$t('app.contact.pageTitle');
  }

  public render() {
    // Change colors based on the breakpoint
    const stripeColor =
      ['lg', 'xl'].indexOf(this.$vuetify.breakpoint.name.toString()) > -1
        ? 'rgba(255, 255, 255, 0.8)'
        : 'rgba(198, 197, 222, 0.9)';

    return (
      <div>
        <background-wrapper imgUrl={headerBg} class='header-bg-z-index'>
          <background-wrapper stripeColor={stripeColor}>
            <v-container class='px-0 pt-0'>
              <Header />
            </v-container>
          </background-wrapper>
        </background-wrapper>
        <v-container class='custom-content'>
          <v-row justify='space-around'>
            <v-col cols='12' md='6'>
              <v-card>
                <v-card-title>Elixír tours s.r.o.</v-card-title>
                <v-card-text>
                  <p>Provozovna:</p>
                  <p>Prague Seasons, Legerova 1853/24, 120 00 Praha</p>
                  <p>
                    <a href='tel:+420603447755'>
                      <v-icon left>mdi-phone</v-icon>
                      +420 603 44 77 55
                    </a>
                  </p>
                  <p>
                    <a href='tel:+420606244876'>
                      <v-icon left>mdi-phone</v-icon>
                      +420 606 244 876
                    </a>
                  </p>
                  <p>
                    <a href='mailto:info@elixirtours.cz'>
                      <v-icon left>mdi-email</v-icon>
                      info@elixirtours.cz
                    </a>
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols='12' md='6'>
              <v-card height='100%'>
                <v-card-title>Provozovatel: Elixír tours s.r.o.</v-card-title>
                <v-card-text>
                  <p>
                    <strong>Sídlo:</strong> Holšická 1327, Praha 9, 190 16
                  </p>
                  <p>
                    <strong>IČ:</strong> 29026270
                  </p>
                  <p>
                    <strong>DIČ:</strong> CZ 29026270
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <p>
            Společnost Elixír tours s.r.o. je zapsaná v OR vedené MS v Praze,
            oddíl C, vložka 160946.
          </p>
          <v-row justify='center'>
            <img src={acckaMember} alt='ACCKA member logo' />
          </v-row>
          <section>
            <h2 class='title'>Číslo účtu CK Elixír tours</h2>
            <ul>
              <li>
                Číslo účtu pro cestovní kancelář Elixír tours:{' '}
                <strong>KB 107-9161320217/0100</strong>
              </li>
              <li>
                Elixír Euro účet: <strong>CZ2301000001079106380217</strong>
              </li>
              <li>
                Elixír USD účet: <strong>CZ3701000001079106390247</strong>
              </li>
            </ul>
          </section>
          <section>
            <h2 class='title'>Naše pojištění</h2>
            <ul>
              <li>
                Elixír tours má u ERV evropské pojišťovny, a.s. uzavřenou{' '}
                <strong>pojistnou smlouvu č.1180000614</strong> na pojištění
                záruky pro případ úpadku cestovní kanceláře na rok 2024.
              </li>
              <li>
                Elixír tours má u ERV evropské pojišťovny, a.s. uzavřenou{' '}
                <strong>pojistnou smlouvu č. 1160000844</strong> na pojištění
                obecné odpovědnosti za škodu pro pořadatele zájezdu.
              </li>
            </ul>
            <p>
              Pro prověření, že naše CK má sjednáno povinné pojištění cestovních
              kanceláří v případě úpadku, klikněte zde:
              <a
                href='https//www.ervpojistovna.cz/cs/ck-pojistene-pro-pripad-upadku'
                target='_blank'
              >
                https://www.ervpojistovna.cz/cs/ck-pojistene-pro-pripad-upadku
              </a>
            </p>
          </section>
          <section>
            <p>
              Níže scan originálních listin pojištění CK proti úpadku a za
              odpovědnost za případné škody:
            </p>
            <ul>
              <li>
                <a href={insuranceIns} download='pojisteni-ins.pdf'>
                  ERV – pojištění záruky pro případ úpadku CK
                </a>
              </li>
              <li>
                <a href={insuranceOdp} download='pojisteni-odp.pdf'>
                  ERV – obecné pojištění odpovědnosti za škodu pro pořadatele
                  zájezdu
                </a>
              </li>
            </ul>
          </section>
          <section>
            <h2 class='title'>Zde je ke stažení:</h2>
            <ul>
              <li>
                <a href={concession} download='koncesni-listina.jpg'>
                  Koncese na provozování cestovní kanceláře
                </a>
              </li>
              {/* TODO: Replace homepage route with terms */}
              <li>
                <router-link to={getLocalizedRoute(routes.terms, this.$router)}>
                  Všeobecné podmínky
                </router-link>
              </li>
            </ul>
          </section>
          <section>
            <h2 class='title'>Certifikát kvality</h2>
            <p>
              <a href={qualityCert} download='q22110-elixir-tours.pdf'>
                Certifikát Pečujeme o kvalitu
              </a>
            </p>
          </section>
          <section>
            <p>
              <strong>
                Spolupracujeme se všemi předními společnostmi zaměstnaneckých
                benefitů
              </strong>
              : (akceptujeme poukázky, šeky, karty, on-line poukázky, atd.)
            </p>
            <ul>
              <li>SODEXO Holiday pass</li>
              <li>Flexi pass</li>
              <li>Relax pass</li>
              <li>Dárkový poukaz BENEFITY a.s.</li>
              <li>EDENRED s.r.o.</li>
              <li>BENEFIT PLUS a.s.</li>
              <li>CHEQUE DÉJEUNER – unišeky</li>
              <li>Gallery Beta</li>
            </ul>
          </section>
        </v-container>
      </div>
    );
  }
}

import { IS_MOBILE_BY_DEFAULT } from '~/../node_modules/elixir-theme/src/constants/MobileConstants';
import { Component, Mixins, Watch } from 'vue-property-decorator';

import headerBg from '~/assets/images/header-bg-default.jpg';
import acckaMember from '~/assets/images/accka-member.jpg';
import Header from '~/components/templates/Header';
import HeadManagement from '~/mixins/HeadManagement';
import EnquiryForm from '~/components/templates/EnquiryForm';
import TailoredOfferForm from '~/components/templates/TailoredOfferForm';

@Component
export default class About extends Mixins(HeadManagement) {
  protected isMobile: boolean = IS_MOBILE_BY_DEFAULT;

  protected displayEnquiryForm: boolean = false;

  public title() {
    return this.$t('app.about.pageTitle');
  }

  public render() {
    // Change colors based on the breakpoint
    const stripeColor =
      ['lg', 'xl'].indexOf(this.$vuetify.breakpoint.name.toString()) > -1
        ? 'rgba(255, 255, 255, 0.8)'
        : 'rgba(198, 197, 222, 0.9)';

    return (
      <div>
        <background-wrapper imgUrl={headerBg} class='header-bg-z-index'>
          <background-wrapper stripeColor={stripeColor}>
            <v-container class='px-0 pt-0'>
              <Header />
            </v-container>
          </background-wrapper>
        </background-wrapper>
        <v-container class='custom-content'>
          <h1 class='elix-title accent--text'>
            Exotická dovolená podle Vašich představ
          </h1>
          <p>
            S veškerou svou energií, láskou, nadšením a nabitými znalostmi a
            zkušenostmi z cest a z cestovního ruchu pro Vás již 9. rokem
            připravujeme DOVOLENÉ, SVATBY, LÍBÁNKY a oslavy výročí svatby pro
            nás na těch nejkrásnějších místech na světě – Seychelách, Mauriciu,
            Maledivách, Srí Lance, Zanzibaru a dalších exotických ostrovech v
            Indickém oceánu. K nim jsme pouze přidali v kratší doletové
            vzdálenosti Spojené arabské Emiráty a Omán.
          </p>
          <p>
            Navazujeme na zkušenosti naší cestovní agentury, která na trhu
            úspěšně působí 14 let.
          </p>
          <h2 class='title primary--text'>Naše nabídka</h2>
          <p>
            Naše portfolio tvoří pouze 10 destinací. Všechny je však známe
            osobně, všechny pravidelně navštěvujeme. Téměř všechny nabízené
            hotely máme osobně prověřené. Naše „Velká trojka“ jsou SEYCHELY,
            MAURICIUS, MALEDIVY. Seychely a Mauricius pak již považujeme téměř
            za svůj „druhý domov“.
          </p>
          <h2 class='title primary--text'>Naši partneři</h2>
          <p>
            Své partnery v destinacích si pečlivě vybíráme, prověřujeme a jsme s
            nimi v každodenním kontaktu. Nepřekupujeme zájezdy jiných evropských
            cestovních kanceláří či cestovních agentur, ani nepoužíváme jejich
            rezervační systémy. Zájezdy pro naše klienty si ve „svých
            destinacích“ organizujeme sami, letenky i ubytování platíme
            dostatečně včas a vždy před odletem klientů. Naše podnikání také
            není zatíženo půjčkami. Naši klienti vědí, že jsme jim stále
            nablízku a hledáme pro ně ty nejoptimálnější varianty. Svých klientů
            si nesmírně vážíme a "sneseme jim modré z nebe"
          </p>
          <h2 class='title primary--text'>Naše práce je pro nás posláním</h2>
          <p>
            Poradíme Vám, kam jet, kde se ubytovat a co kde dělat, abyste si
            svou zaslouženou dovolenou užili na 200 %. A díky dokonalým
            znalostem prostředí Vám dokážeme ušetřit čas i peníze. Proč platit
            více, než musíte? Proč jezdit někam, kde nevíte, že je to TOP? Kolik
            lidí z Vašeho okolí bylo zklamaných, protože dovolená nesplnila
            jejich očekávání. Aby se to nestalo – od toho jsme tu my :)
          </p>
          <p>
            <strong>
              Vykouzlíme Vám dovolenou, svatbu či líbánky přesně podle Vašich
              představ! Volejte <a href='tel:+420603447755'>603 44 77 55</a> či
              pošlete{' '}
              <a role='button' onClick={() => (this.displayEnquiryForm = true)}>
                poptávkový formulář
              </a>
              .
            </strong>
          </p>

          <h3 class='subtitle primary--text'>Váš Elixír tým ♥</h3>
          <img src={acckaMember} alt='ACCKA member logo' />
        </v-container>
        <v-dialog v-model={this.displayEnquiryForm} fullscreen>
          <v-card flat>
            <v-container>
              <v-row className='fill-height' justify='center' align='center'>
                <v-col>
                  <TailoredOfferForm isMobile={this.isMobile} />
                </v-col>
              </v-row>
            </v-container>
            <v-btn
              icon
              absolute
              top
              right
              onClick={() => (this.displayEnquiryForm = false)}
            >
              <v-icon>$vuetify.icons.close</v-icon>
            </v-btn>
          </v-card>
        </v-dialog>
      </div>
    );
  }

  @Watch('$vuetify.breakpoint.name')
  public setMobile() {
    this.isMobile =
      this.$vuetify.breakpoint.name === 'xs' ||
      this.$vuetify.breakpoint.name === 'sm' ||
      this.$vuetify.breakpoint.name === 'md';
  }
}
